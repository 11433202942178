.page-not-found {
  background-color: white;
  color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.img {
  height: 50vh;
}

.container {
  margin: 200px;
  text-align: center;
}

.text-box {
  font-size: 4rem;
}

.text-box-2 {
  margin: 200px auto;
  padding-right: 25rem;
  padding-top: 10rem;
  font-size: 2rem;
}

hr {
  border-bottom: solid 3px rgba(144, 144, 144, 0.25);
  margin: 2em 0;
}

.home-link {
  background-color: white;
  padding: 5px;

  &:hover {
    cursor: pointer;
    font-size: 2.1rem;
  }
}
