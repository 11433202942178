.ministry-preview {
  display: flex;
  flex-direction: column;

  .preview {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .ministry-preview {
    .preview {
      flex-direction: column;
    }
  }
}
