.ministry-item {
  width: 22vw;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 30px;

  .image {
    width: 100%;
    height: 90%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 5px;
  }

  .custom-button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 150px;
    display: none;
  }

  .ministry-header {
    width: 100%;
    height: 10%;
    display: flex;
    align-content: center;
  }

  .title {
    width: 90%;
    margin-bottom: 15px;
  }

  &:hover {
    .image {
      transform: scale(1.05);
      opacity: 0.7;
    }
    .custom-button {
      opacity: 0.85;
      display: flex;
    }
  }
}

.modal {
  position: fixed;
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: translate(50%, 50%);
}

@media (max-width: 1024px) {
  .ministry-item {
    width: 90vw;
    margin: auto;

    .custom-button {
      display: flex;
      position: static;
    }
  }

  .modal {
    left: 10vw;
    top: 10vh;
    margin-right: 0;
    transform: translate(0, 0);
  }
}
