body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

body {
  background: #202024;
}

body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
  -webkit-animation: none !important;
  animation: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

/* Type */

body,
input,
select,
textarea {
  color: rgba(255, 255, 255, 0.75);
  font-family: 'Montserrat', sans-serif;
  font-size: 14pt;
  font-weight: 300;
  line-height: 1.65;
}

@media screen and (max-width: 1680px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

@media screen and (max-width: 1280px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

@media screen and (max-width: 980px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 736px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 480px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

a {
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  outline: 0;
}

a:hover {
  color: #fff;
  border: none;
}

strong,
b {
  color: #fff;
  font-weight: 400;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 0 1em 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.35em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 0.9em;
}

h6 {
  font-size: 0.7em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px rgba(144, 144, 144, 0.25);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: solid 1px rgba(144, 144, 144, 0.25);
  font-family: 'Courier New', monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: 'Courier New', monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px rgba(144, 144, 144, 0.25);
  margin: 2em 0;
}

hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.heading-primary-main {
  -webkit-animation: moveInRight 1s ease-out;
          animation: moveInRight 1s ease-out;
}

.heading-primary-sub {
  -webkit-animation: moveInLeft 1s ease-out;
          animation: moveInLeft 1s ease-out;
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header p {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  margin: 0 0 1.5em 0;
}

header h2 + p {
  font-size: 1.25em;
  margin-top: -1em;
}

header h3 + p {
  font-size: 1.1em;
  margin-top: -0.8em;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9em;
  margin-top: -0.6em;
}

/* Box */

.thumbnails {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: stretch;
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.thumbnails .box {
  margin: 0 1em 2em 1em;
  width: 30%;
}

@media screen and (max-width: 1280px) {
  .thumbnails .box {
    width: 45%;
  }
}

@media screen and (max-width: 736px) {
  .thumbnails .box {
    width: 100%;
  }
}

/* Form */

form {
  margin: 0 0 2em 0;
}

label {
  color: #fff;
  display: block;
  font-size: 0.9em;
  font-weight: 400;
  margin: 0 0 1em 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: none;
  border: solid 1px rgba(144, 144, 144, 0.25);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type='text']:invalid,
input[type='password']:invalid,
input[type='email']:invalid,
input[type='tel']:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='tel']:focus,
select:focus,
textarea:focus {
  border-color: #ff8686;
  box-shadow: 0 0 0 1px #ff8686;
}

.select-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}

.select-wrapper:before {
  content: '\F078';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.select-wrapper:before {
  color: rgba(144, 144, 144, 0.25);
  display: block;
  height: 2.75em;
  line-height: 2.75em;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 2.75em;
}

.select-wrapper select::-ms-expand {
  display: none;
}

input[type='text'],
input[type='password'],
input[type='email'],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

input[type='checkbox'],
input[type='radio'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75);
  /* cursor: pointer; */
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  padding-left: 2.4em;
  padding-right: 0.75em;
  position: relative;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: solid 1px rgba(144, 144, 144, 0.25);
  content: '';
  display: inline-block;
  height: 1.65em;
  left: 0;
  line-height: 1.58125em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.65em;
}

input[type='checkbox']:checked + label:before,
input[type='radio']:checked + label:before {
  background: #ff8686;
  border-color: #ff8686;
  color: #ffffff;
  content: '\F00C';
}

input[type='checkbox']:focus + label:before,
input[type='radio']:focus + label:before {
  border-color: #ff8686;
  box-shadow: 0 0 0 1px #ff8686;
}

input[type='checkbox'] + label:before {
  border-radius: 4px;
}

input[type='radio'] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

.formerize-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

/* Box */

.box {
  border-radius: 4px;
  margin-bottom: 2em;
  background: #2c2c32;
  text-align: center;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box .image.fit {
  margin: 0;
}

.box .image img {
  border-radius: 4px 4px 0 0;
}

.box .inner {
  padding: 1.5em;
  width: 100% !important;
}

@media screen and (max-width: 480px) {
  .box .inner {
    padding: 1em;
  }
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* Image */

.image {
  border-radius: 4px;
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  border-radius: 4px;
  display: block;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px rgba(144, 144, 144, 0.25);
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  text-align: center;
  padding-left: 0;
  padding-right: 1em;
}

ul.icons li a {
  display: block;
  margin: 0;
  padding: 0.5em;
  color: white;
  text-decoration: none;
}

ul.icons li a:before {
  font-size: 1.5em;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
}

ul.icons li a:hover {
  background-color: #202024;
  color: #fff;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.actions li {
  display: inline-block;
  padding: 0 1em 0 0;
  vertical-align: middle;
}

ul.actions li:last-child {
  padding-right: 0;
}

ul.actions.small li {
  padding: 0 0.5em 0 0;
}

ul.actions.vertical li {
  display: block;
  padding: 1em 0 0 0;
}

ul.actions.vertical li:first-child {
  padding-top: 0;
}

ul.actions.vertical li > * {
  margin-bottom: 0;
}

ul.actions.vertical.small li {
  padding: 0.5em 0 0 0;
}

ul.actions.vertical.small li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  display: table;
  margin-left: -1em;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1em);
}

ul.actions.fit li {
  display: table-cell;
  padding: 0 0 0 1em;
}

ul.actions.fit li > * {
  margin-bottom: 0;
}

ul.actions.fit.small {
  margin-left: -0.5em;
  width: calc(100% + 0.5em);
}

ul.actions.fit.small li {
  padding: 0 0 0 0.5em;
}

@media screen and (max-width: 480px) {
  ul.actions {
    margin: 0 0 2em 0;
  }

  ul.actions li {
    padding: 1em 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }

  ul.actions li:first-child {
    padding-top: 0;
  }

  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }

  ul.actions li > *.icon:before {
    margin-left: -2em;
  }

  ul.actions.small li {
    padding: 0.5em 0 0 0;
  }

  ul.actions.small li:first-child {
    padding-top: 0;
  }
}

dl {
  margin: 0 0 2em 0;
}

dl dt {
  display: block;
  font-weight: 400;
  margin: 0 0 1em 0;
}

dl dd {
  margin-left: 2em;
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px rgba(144, 144, 144, 0.25);
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.075);
}

table td {
  padding: 0.75em 0.75em;
}

table th {
  color: #fff;
  font-size: 0.9em;
  font-weight: 400;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}

table thead {
  border-bottom: solid 2px rgba(144, 144, 144, 0.25);
}

table tfoot {
  border-top: solid 2px rgba(144, 144, 144, 0.25);
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px rgba(144, 144, 144, 0.25);
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Button */

input[type='submit'],
input[type='reset'],
input[type='button'],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: #70c7be;
  border-radius: 4px;
  border: 0;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  height: 2.85em;
  line-height: 2.95em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
button:hover,
.button:hover {
  background-color: #82cec6;
}

input[type='submit']:active,
input[type='reset']:active,
input[type='button']:active,
button:active,
.button:active {
  background-color: #5ec0b6;
}

input[type='submit'].icon,
input[type='reset'].icon,
input[type='button'].icon,
button.icon,
.button.icon {
  padding-left: 1.35em;
}

input[type='submit'].icon:before,
input[type='reset'].icon:before,
input[type='button'].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}

input[type='submit'].fit,
input[type='reset'].fit,
input[type='button'].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}

input[type='submit'].small,
input[type='reset'].small,
input[type='button'].small,
button.small,
.button.small {
  font-size: 0.8em;
}

input[type='submit'].big,
input[type='reset'].big,
input[type='button'].big,
button.big,
.button.big {
  font-size: 1.35em;
}

input[type='submit'].disabled,
input[type='submit']:disabled,
input[type='reset'].disabled,
input[type='reset']:disabled,
input[type='button'].disabled,
input[type='button']:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  background-color: rgba(255, 255, 255, 0.75) !important;
  box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
  color: #202024 !important;
  cursor: default;
  opacity: 0.25;
}

input[type='submit'].style2,
input[type='reset'].style2,
input[type='button'].style2,
button.style2,
.button.style2 {
  background-color: #7f92cf;
}

input[type='submit'].style2:hover,
input[type='reset'].style2:hover,
input[type='button'].style2:hover,
button.style2:hover,
.button.style2:hover {
  background-color: #92a2d6;
}

input[type='submit'].style2:active,
input[type='reset'].style2:active,
input[type='button'].style2:active,
button.style2:active,
.button.style2:active {
  background-color: #6c82c8;
}

input[type='submit'].style3,
input[type='reset'].style3,
input[type='button'].style3,
button.style3,
.button.style3 {
  background-color: #9d7ed0;
}

input[type='submit'].style3:hover,
input[type='reset'].style3:hover,
input[type='button'].style3:hover,
button.style3:hover,
.button.style3:hover {
  background-color: #ab91d7;
}

input[type='submit'].style3:active,
input[type='reset'].style3:active,
input[type='button'].style3:active,
button.style3:active,
.button.style3:active {
  background-color: #8f6bc9;
}

@media screen and (max-width: 480px) {
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button,
  .button {
    padding: 0;
  }
}

/* Poptrox */

.poptrox-popup {
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  background: #000;
  box-shadow: 0 0.5em 3em 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2em;
  overflow: hidden;
}

.poptrox-popup .closer {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 11000;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  white-space: nowrap;
  text-indent: 2em;
  overflow: hidden;
  width: 2em;
  height: 2em;
  display: block;
}

.poptrox-popup .closer:before {
  content: '\F00D';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
  display: block;
  position: relative;
  text-indent: 0;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  color: #ffffff !important;
  width: 2em;
  height: 2em;
  line-height: 2em;
}

.poptrox-popup:hover .closer {
  opacity: 0.5;
}

.poptrox-popup:hover .closer:hover {
  opacity: 1;
}

@media screen and (max-width: 736px) {
  .poptrox-popup {
    padding: 0;
  }

  .poptrox-popup .closer {
    display: none !important;
  }
}

/* Banner */

#banner {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 8em 4em 6em 4em;
  min-height: 70vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
}

#banner .inner {
  text-align: center;
  position: relative;
  z-index: 2;
}

#banner h1 {
  color: #fff;
  font-size: 5em;
  font-weight: 400;
  font-family: 'Montserrat';
  margin: 0;
}

@media screen and (max-width: 980px) {
  #banner h1 {
    font-size: 5em;
  }
}

@media screen and (max-width: 736px) {
  #banner h1 {
    font-size: 3em;
  }
}

#banner p {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.5em;
  font-weight: 300;
}

@media screen and (max-width: 980px) {
  #banner p {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  #banner p {
    font-size: 1.25em;
  }
}

#banner a {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  border-bottom: 1px dotted;
}

#banner a:hover {
  color: #fff;
  border: none;
}

#banner .more {
  background-image: url(/static/media/arrow.f61e6e6d.svg);
  background-position: center 1.35em;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid #fff;
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.75);
  display: block;
  height: 4em;
  text-indent: 4em;
  overflow: hidden;
  white-space: nowrap;
  width: 4em;
  z-index: 2;
  margin: 0 auto 2em auto;
}

#banner .more:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #fff;
  color: #fff;
}

#banner video {
  -webkit-transform: translateX(50%) translateY(50%);
  transform: translateX(50%) translateY(50%);
  position: absolute;
  bottom: 50%;
  right: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

#banner img {
  display: none;
}

#banner:before {
  -webkit-transition: opacity 3s ease;
  transition: opacity 3s ease;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  content: '';
  background-color: #42424f;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.45;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  #banner {
    padding-top: 0;
    background-color: black;
  }

  #banner video {
    display: none;
  }
  #banner img {
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }

  #banner .inner {
    position: absolute;
  }
}

@media screen and (max-width: 980px) {
  /* #banner br {
    display: none;
  } */
}

@media screen and (max-width: 736px) {
  /* #banner {
    min-height: 0;
    padding: 6em 2em 4em 2em;
  }

  #banner br {
    display: none;
  } */
}

body.is-loading #banner:before {
  opacity: 1;
}

/* Main */

#main {
  padding: 4em 0 2em 0;
}

@media screen and (max-width: 736px) {
  #main {
    padding: 3em 0 1em 0;
  }
}

#main .inner {
  width: 90%;
  max-width: 80em;
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  #main .inner {
    width: 95%;
  }
}

/* Footer */

#footer {
  padding: 4em 0 2em 0;
  background-color: #1b1b1f;
  text-align: center;
}

#footer .inner {
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 980px) {
  #footer .inner {
    width: 75%;
  }
}

@media screen and (max-width: 480px) {
  #footer .inner {
    width: 90%;
  }
}

#footer .copyright {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9em;
  margin: 0 0 2em 0;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 736px) {
  #footer {
    padding: 3em 0 1em 0;
  }
}

.column {
  float: left;
  width: 33.33%;
  text-align: center;
}

.row:after {
  content: '';
  display: inline-block;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

div .column:hover {
  cursor: pointer;
}

.wfac-banner {
  text-align: center;
  padding-bottom: 20px;
}

.wfac-banner img:hover {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .wfac-banner img {
    width: 95vw;
    padding-top: -20em;
  }
}

.footer {
  position: absolute;
  bottom: 0; }

a {
  color: white;
  text-decoration: none !important; }

.header {
  background-color: white;
  height: 20vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 25px;
  overflow: hidden; }
  .header a {
    text-decoration: none;
    text-align: center; }
  .header .logo-container {
    height: 100%;
    width: 10vw;
    padding: 0px 30px 30px 30px; }
  .header .options {
    width: 50%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .header .options .mobile-header {
      display: none; }
    .header .options .option {
      padding: 1em 1em;
      cursor: pointer;
      color: black;
      border-bottom: 1px black; }
    .header .options .first {
      display: none; }

@media (max-width: 599px) {
  .header {
    display: block; }
    .header .options {
      width: 100%;
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly;
      padding-bottom: 0.5rem; }
      .header .options .first {
        display: -webkit-flex;
        display: flex; }
      .header .options .mobile-header {
        color: black;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center; }
  .logo-container {
    display: none; }
  .options {
    margin: auto;
    padding: auto;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

.header-map {
  text-align: center;
  padding-top: 5rem; }

.location-page {
  width: 85vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 30px auto; }

.location-text {
  position: relative;
  top: 3em;
  right: 20em; }

.location-map {
  position: relative;
  left: 10em; }

@media (max-width: 599px) {
  .map-container {
    display: none; }
  .location-text {
    text-align: center;
    margin: auto;
    right: 0;
    padding-bottom: 2.5rem; } }

.custom-button {
  min-width: 165px;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .custom-button:hover {
    background-color: black;
    color: white;
    border: 1px solid black; }
  .custom-button.inverted {
    background-color: white;
    color: black;
    border: 1px solid black; }
    .custom-button.inverted:hover {
      background-color: black;
      color: white;
      border: none; }

.ministry-description {
  color: black;
  background-color: white;
  height: 50vh;
  width: 50vw;
  padding: 10px; }
  .ministry-description .image {
    width: 50%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 5px; }
  .ministry-description .ministry-text {
    overflow: auto; }

.row {
  display: -webkit-flex;
  display: flex; }

.column {
  -webkit-flex: 50% 1;
          flex: 50% 1; }

@media (max-width: 1024px) {
  .ministry-description {
    height: 80vh;
    width: 80vw;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .ministry-description .image {
      width: 100%;
      height: auto; } }

.ministry-item {
  width: 22vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 350px;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 30px; }
  .ministry-item .image {
    width: 100%;
    height: 90%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 5px; }
  .ministry-item .custom-button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 150px;
    display: none; }
  .ministry-item .ministry-header {
    width: 100%;
    height: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center; }
  .ministry-item .title {
    width: 90%;
    margin-bottom: 15px; }
  .ministry-item:hover .image {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    opacity: 0.7; }
  .ministry-item:hover .custom-button {
    opacity: 0.85;
    display: -webkit-flex;
    display: flex; }

.modal {
  position: fixed;
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%); }

@media (max-width: 1024px) {
  .ministry-item {
    width: 90vw;
    margin: auto; }
    .ministry-item .custom-button {
      display: -webkit-flex;
      display: flex;
      position: static; }
  .modal {
    left: 10vw;
    top: 10vh;
    margin-right: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.ministry-preview {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .ministry-preview .preview {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

@media (max-width: 1024px) {
  .ministry-preview .preview {
    -webkit-flex-direction: column;
            flex-direction: column; } }

.ministry-overview {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.page-not-found {
  background-color: white;
  color: black;
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.img {
  height: 50vh; }

.container {
  margin: 200px;
  text-align: center; }

.text-box {
  font-size: 4rem; }

.text-box-2 {
  margin: 200px auto;
  padding-right: 25rem;
  padding-top: 10rem;
  font-size: 2rem; }

hr {
  border-bottom: solid 3px rgba(144, 144, 144, 0.25);
  margin: 2em 0; }

.home-link {
  background-color: white;
  padding: 5px; }
  .home-link:hover {
    cursor: pointer;
    font-size: 2.1rem; }

.missions {
  text-align: center; }

.donate-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.donate-header {
  text-align: center;
  padding-top: 2.5rem; }
  .donate-header-content {
    font-style: italic;
    padding: 2em; }

.donate-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto; }

.donate-inner {
  padding: 5rem;
  text-align: center; }

.donate-image {
  display: inline-block; }

.donate-text {
  text-align: center; }
  .donate-text-inner {
    font-size: 90%; }

.donate-link {
  color: skyblue; }

@media only screen and (max-width: 1024px) {
  .donate-icons {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .donate-inner {
    padding: 1rem; } }

.youtube-section {
  width: 100vw;
  margin-bottom: -50vh; }

.pictures {
  text-align: center;
  width: 100vw;
  padding-bottom: 1.75em; }
  .pictures img {
    width: 70%; }

.advert {
  text-align: center;
  padding-bottom: 2em; }
  .advert img {
    width: 35vw;
    padding: 1em; }

@media screen and (max-width: 800px) {
  .youtube-section {
    margin-bottom: -50px; }
  .advert h2 {
    font-size: 0.8em; }
  .advert img {
    width: 80vw; } }

