.header-map {
  text-align: center;
  padding-top: 5rem;
}

.location-page {
  width: 85vw;
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
}

.location-text {
  position: relative;
  top: 3em;
  right: 20em;
}

.location-map {
  position: relative;
  left: 10em;
}

@media (max-width: 599px) {
  .map-container {
    display: none;
  }
  .location-text {
    text-align: center;
    margin: auto;
    right: 0;
    padding-bottom: 2.5rem;
  }
}
