.ministry-description {
  color: black;
  background-color: white;
  height: 50vh;
  width: 50vw;
  padding: 10px;

  .image {
    width: 50%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    margin-bottom: 5px;
  }

  .ministry-text {
    overflow: auto;
  }
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

@media (max-width: 1024px) {
  .ministry-description {
    height: 80vh;
    width: 80vw;
    flex-direction: column;

    .image {
      width: 100%;
      height: auto;
    }
  }
}
