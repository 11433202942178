.youtube-section {
  width: 100vw;
  margin-bottom: -50vh;
}

.pictures {
  text-align: center;
  width: 100vw;
  padding-bottom: 1.75em;
  img {
    width: 70%;
  }
}

.advert {
  text-align: center;
  padding-bottom: 2em;
  img {
    width: 35vw;
    padding: 1em;
  }
}

@media screen and (max-width: 800px) {
  .youtube-section {
    margin-bottom: -50px;
  }
  .advert {
    h2 {
      font-size: 0.8em;
    }
    img {
      width: 80vw;
    }
  }
}
