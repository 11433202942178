.header {
  background-color: white;
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  overflow: hidden;

  a {
    text-decoration: none;
    text-align: center;
  }

  .logo-container {
    height: 100%;
    width: 10vw;
    padding: 0px 30px 30px 30px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mobile-header {
      display: none;
    }
    .option {
      padding: 1em 1em;
      cursor: pointer;
      color: black;
      border-bottom: 1px black;
    }

    .first {
      display: none;
    }
  }
}

@media (max-width: 599px) {
  .header {
    display: block;

    .options {
      width: 100%;
      justify-content: space-evenly;
      padding-bottom: 0.5rem;

      .first {
        display: flex;
      }

      .mobile-header {
        color: black;
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .logo-container {
    display: none;
  }
  .options {
    margin: auto;
    padding: auto;
    flex-wrap: wrap;
  }
}
