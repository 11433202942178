.donate-container {
  display: flex;
  align-items: center;
}

.donate-header {
  text-align: center;
  padding-top: 2.5rem;
  &-content {
    font-style: italic;
    padding: 2em;
  }
}

.donate-icons {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.donate-inner {
  padding: 5rem;
  text-align: center;
}

.donate-image {
  display: inline-block;
}

.donate-text {
  text-align: center;

  &-inner {
    font-size: 90%;
  }
}

.donate-link {
  color: skyblue;
}

@media only screen and (max-width: 1024px) {
  .donate-icons {
    flex-direction: column;
  }
  .donate-inner {
    padding: 1rem;
  }
}
